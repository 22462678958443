<template>
    <styled-slideout
        :value="value"
        @input="$emit('input', $event)">
        <div class="pa-5">
            <h2>Install Request Emails</h2>
            <p class="mb-4">
                Use the example messaging below to obtain approval from {{ dealer.name }}
                and have {{ siteProvider.name }} install event flow using the approval.
            </p>

            <div
                v-if="siteProvider.support_phone || siteProvider.support_email"
                class="contact-panel">
                <p>
                    {{ siteProvider.name }} Contact Information
                </p>
                <div class="layout wrap">
                    <data-field
                        v-if="siteProvider.support_phone"
                        class="mr-3"
                        no-indent>
                        <template #label>
                            Phone
                        </template>
                        <a
                            :href="`tel:${siteProvider.support_phone}`"
                            target="_blank">
                            {{ siteProvider.support_phone }}
                        </a>
                    </data-field>
                    <data-field
                        v-if="siteProvider.support_email"
                        no-indent>
                        <template #label>
                            Email
                        </template>
                        <a
                            :href="`mailto:${siteProvider.support_email}`"
                            target="_blank">
                            {{ siteProvider.support_email }}
                        </a>
                    </data-field>
                </div>
            </div>

            <styled-list class="my-4">
                <div>
                    <h3>
                        Request for Approval
                    </h3>
                    <p>
                        Send this message to an authorized contact at {{ dealer.name }} that
                        authorizes {{ siteProvider.name }} to install the script.
                    </p>
                    <p>
                        <em>
                            Note: if you are already an authorized contact at {{ siteProvider.name }}
                            for {{ dealer.name }} you can skip this step
                        </em>
                    </p>
                    <copy-text-area
                        hide-download
                        :email-subject="`[APPROVAL REQ'D] ${siteProvider.name} <> ${dealer.name} request RE: ${dealer.agency.name} GTM`"
                        class="mb-4">
                        <p>
                            Greetings!
                        </p>
                        <p>
                            In order to activate the system that enables call tracking, retargeting
                            and enhanced reporting we need to install event flow on
                            {{ dealerHostname }}.
                        </p>
                        <p>
                            To proceed with this process {{ siteProvider.name }} will need your
                            approval.
                        </p>
                        <p>
                            Please REPLY ALL with "Approved" so we can work with {{ siteProvider.name }}
                            to finalize the event flow installation.
                        </p>
                        <p>
                            Thanks!<br>
                            The {{ dealer.agency.name }} Team
                        </p>
                    </copy-text-area>
                </div>
                <div>
                    <h3>Request for Install</h3>
                    <p>
                        Forward the approval received from the previous message
                        to {{ siteProvider.name }} with the message below.
                    </p>

                    <copy-text-area
                        hide-download
                        :email-to="!!siteProvider.support_email ? siteProvider.support_email : ''"
                        :email-subject="`Event Flow Installation: ${dealer.name} (${dealerHostname})`"
                        class="mb-4">
                        <p>
                            Hello {{ siteProvider.name }}!
                        </p>
                        <p>
                            We're in the process of setting up our advertising system with {{ dealer.name }}.
                            To finalize the process we'd like to install event flow on {{ dealerHostname }}.
                        </p>
                        <p>
                            You can find the scripts to install at the link below:<br>
                            <a :href="publicEventFlowUrl">
                                {{ publicEventFlowUrl }}
                            </a>
                        </p>
                        <p>
                            Our contact at {{ dealer.name }} has approved this change in the thread below.
                        </p>
                        <p>
                            Thanks!<br>
                            The {{ dealer.agency.name }} Team
                        </p>
                    </copy-text-area>
                </div>
            </styled-list>
        </div>
    </styled-slideout>
</template>

<script>
import { mapGetters } from 'vuex';
import DataField from '@/components/globals/DataField';
import StyledSlideout from '@/components/globals/StyledSlideout';
import StyledList from '@/components/globals/StyledList';
import CopyTextArea from '@/components/globals/CopyTextArea';

export default {
    name: 'ResendEventFlow',
    components: {
        StyledSlideout,
        StyledList,
        CopyTextArea,
        DataField
    },
    props: {
        dealer: {
            type: Object,
            required: true
        },
        siteProvider: {
            type: Object,
            required: true
        },
        value: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        publicEventFlowUrl() {
            const eventFlowRoute = this.$router.resolve({
                name: 'event-flow-setup',
                params: {
                    uuid: this.dealer.uuid
                }
            });

            return window.location.origin + eventFlowRoute.href;
        },
        ...mapGetters(['dealerHostname'])
    },
    watch: {
        value(newValue, oldValue) {
            // If we're transitioning to open and the form was previously
            // submitted reset it
            if (newValue === true
                && oldValue === false
                && this.complete === true) {
                this.complete = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.contact-panel {
    padding: 30px;
    background-color: rgba($gray, 0.05);
}
</style>