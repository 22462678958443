<template>
    <div>
        <new-tag-manager-provider-request-slideout v-model="showNewTagManagerDialog" />

        <styled-slideout
            v-if="currentDealer.tag_manager_container_id"
            v-model="codeDialogOpen">
            <div class="pa-5">
                <h2>Copy Google Tag Manager Code</h2>
                <p>
                    Please paste the primary script below on every page as high in
                    the <strong>&#x3C;head&#x3E;</strong> of the page as possible.
                </p>
                <copy-text-area
                    hide-email
                    :download-filename="`${currentDealer.tag_manager_container_id}-primary.txt`"
                    class="mb-4">
                    <pre>{{ mainScript }}</pre>
                </copy-text-area>
                <p>
                    Additionally, paste the secondary script below immediately after the opening
                    <strong>&#x3C;body&#x3E;</strong> tag on every page.
                </p>
                <copy-text-area
                    hide-email
                    :download-filename="`${currentDealer.tag_manager_container_id}-secondary.txt`"
                    class="mb-4">
                    <pre>{{ secondaryScript }}</pre>
                </copy-text-area>
                <p>
                    If you're sending this to another party, feel free to use the link
                    below which includes these scripts and instructions for the
                    website's developer.
                </p>
                <copy-text-area
                    hide-email
                    hide-download>
                    {{ publicGtmSetupUrl }}
                </copy-text-area>
            </div>
        </styled-slideout>

        <styled-slideout
            v-if="dealerUsingEventFlow"
            v-model="eventFlowCodeDialogOpen">
            <div class="pa-5">
                <h2>Copy Event Flow Code</h2>
                <p>
                    Please paste the script below on every page as high in
                    the <strong>&#x3C;head&#x3E;</strong> of the page as possible.
                </p>
                <copy-text-area
                    :download-filename="`${currentDealer.uuid}-primary.txt`"
                    class="mb-4"
                    hide-email>
                    <pre>{{ eventFlowScript }}</pre>
                </copy-text-area>
                <p>
                    If you're sending this to another party, feel free to use the link
                    below which includes these scripts and instructions for the
                    website's developer.
                </p>
                <copy-text-area
                    hide-email
                    hide-download>
                    {{ publicEventFlowSetupUrl }}
                </copy-text-area>
            </div>
        </styled-slideout>

        <resend-site-provider-dialog
            v-if="siteProvider"
            v-model="messagingDialogOpen"
            :site-provider="siteProvider"
            :dealer="currentDealer" />

        <resend-event-flow-dialog
            v-if="siteProvider"
            v-model="eventFlowMessagingDialogOpen"
            :site-provider="siteProvider"
            :dealer="currentDealer" />

        <v-alert
            :value="(initErrors.length > 0)"
            class="mb-4">
            <p>
                The {{ initErrors.length > 1 ? 'errors' : 'error' }} below occured
                while initializing GTM.  Please contact support for assitance!
            </p>
            <ul>
                <li
                    v-for="error in initErrors"
                    :key="error">
                    {{ error }}
                </li>
            </ul>
        </v-alert>

        <div
            v-if="loadingInit"
            class="layout justify-center py-5">
            <loader label="Initializing..." />
        </div>

        <template v-if="!loadingInit">
            <styled-callout
                v-if="gtmDeployed"
                class="layout align-center mb-4">
                <div class="flex shrink mr-3">
                    <status-icon
                        :value="eventFlowFeatureComplete ? 'success' : 'warning'"
                        size="25" />
                </div>
                <div class="flex">
                    <p v-if="eventFlowFeatureComplete">
                        {{ currentDealer.name }}'s tag manager has been successfully
                        deployed installed on {{ dealerHostname }}. Please note that you cannot
                        change the provider once it's been deployed during onboarding.
                    </p>
                    <p v-else>
                        {{ currentDealer.name }}'s tag manager has been deployed but
                        <strong>you still need to work with {{ currentDealer.name }}'s
                            website provider to install the tag</strong> to finalize this process.
                        Please note that you cannot change the provider once it's been
                        deployed during onboarding.
                    </p>
                </div>
            </styled-callout>

            <div v-if="dealerUsingEventFlow">
                <div>
                    <h2>Install Event Flow</h2>
                    <p>Awesome! Let’s get your event flow installed on this account’s website ({{ dealerHostname }}).</p>
                    <p>You’ll need work with the developer of {{ dealerHostname }} to have the code below installed in order to track events, enable call tracking and build retargeting audience.</p>
                </div>
                <div class="layout align-center justify-center my-5">
                    <styled-button
                        gray
                        outline
                        @click="eventFlowCodeDialogOpen = true">
                        VIEW &amp; COPY CODE
                    </styled-button>
                    <div class="mx-4">
                        OR
                    </div>
                    <styled-button
                        gray
                        outline
                        @click="onDownloadEventFlowCode">
                        DOWNLOAD CODE
                    </styled-button>
                </div>
            </div>

            <div v-else-if="!dealerIsUsingGtm">
                <div>
                    <h2>Install Google Tag Manager Code</h2>
                    <p>Awesome!  Let’s get your pixel installed on this account’s website ({{ dealerHostname }}).</p>
                    <p>You’ll need work with the developer of {{ dealerHostname }} to have the code below installed in order to track events, enable call tracking and build retargeting audience.</p>
                </div>
                <div class="layout align-center justify-center my-5">
                    <styled-button
                        gray
                        outline
                        @click="codeDialogOpen = true">
                        VIEW &amp; COPY CODE
                    </styled-button>
                    <div class="mx-4">
                        OR
                    </div>
                    <styled-button
                        gray
                        outline
                        @click="onDownloadCode">
                        DOWNLOAD CODE
                    </styled-button>
                </div>
            </div>

            <!-- @todo Once we support non-auto site providers this can be removed -->
            <div v-if="dealerInAutoVertical">
                <p>
                    If you know your site provider please select them
                    below. Otherwise, click on the link below.
                </p>
                <div class="mb-5">
                    <div class="layout">
                        <v-autocomplete
                            v-model="siteProviderId"
                            :items="siteProviders"
                            item-text="name"
                            item-value="id"
                            :disabled="gtmDeployed"
                            :loading="loadingSiteProviders"
                            :rules="siteProviderRules"
                            class="styled-field flex xs12 md6 mb-2"
                            label="Select Site Provider"
                            clearable />
                    </div>
                    <a
                        v-if="!siteProviderId"
                        href="#"
                        :disabled="gtmDeployed"
                        @click.prevent="showNewTagManagerDialog = true">
                        Click here if you don’t see your provider
                    </a>
                </div>
                <div
                    v-if="siteProvider && !eventFlowFeatureComplete && !dealerUsingEventFlow">
                    <p class="mb-5">
                        Okay! You’ll need follow these steps to have {{ siteProvider.name }}
                        install the script above on {{ dealerHostname }}
                    </p>
                    <div class="layout wrap">
                        <div class="flex xs12 mb-5 md6">
                            <styled-list align-middle>
                                <div>
                                    Get written approval from an authorized contact at {{ currentDealer.name }}
                                    that authorizes {{ siteProvider.name }} to install this script
                                    (<a
                                        href="#"
                                        @click.prevent="messagingDialogOpen = true">example</a>)
                                </div>
                                <div>
                                    Send a copy of the approval along with the scripts directly to
                                    {{ siteProvider.name }} and request that they install them on {{ dealerHostname }}
                                    (<a
                                        href="#"
                                        @click.prevent="messagingDialogOpen = true">example</a>)
                                </div>
                                <div>
                                    Once {{ siteProvider.name }} has successfully installed the script
                                    visit the account dashboard to verify that BuyerBridge has detected the script
                                </div>
                            </styled-list>
                        </div>
                        <div class="flex xs12 md5 offset-md1 mb-5">
                            <styled-button
                                class="mb-4"
                                outline
                                gray
                                full-width
                                @click="messagingDialogOpen = true">
                                VIEW &amp; SEND EMAILS
                            </styled-button>
                            <div
                                v-if="siteProvider.support_phone || siteProvider.support_email"
                                class="contact-panel">
                                <p>{{ siteProvider.name }} Contact Information</p>
                                <div class="layout wrap">
                                    <data-field
                                        v-if="siteProvider.support_phone"
                                        class="mr-3"
                                        no-indent>
                                        <template #label>
                                            Phone
                                        </template>
                                        <a
                                            :href="`tel:${siteProvider.support_phone}`"
                                            target="_blank">
                                            {{ siteProvider.support_phone }}
                                        </a>
                                    </data-field>
                                    <data-field
                                        v-if="siteProvider.support_email"
                                        no-indent>
                                        <template #label>
                                            Email
                                        </template>
                                        <a
                                            :href="`mailto:${siteProvider.support_email}`"
                                            target="_blank">
                                            {{ siteProvider.support_email }}
                                        </a>
                                    </data-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div
                    v-if="siteProvider && dealerUsingEventFlow">
                    <p class="mb-5">
                        Okay! You’ll need follow these steps to have {{ siteProvider.name }}
                        install the event flow script above on {{ dealerHostname }}
                    </p>
                    <div class="layout wrap">
                        <div class="flex xs12 mb-5 md6">
                            <styled-list align-middle>
                                <div>
                                    Get written approval from an authorized contact at {{ currentDealer.name }}
                                    that authorizes {{ siteProvider.name }} to install this script
                                    (<a
                                        href="#"
                                        @click.prevent="eventFlowMessagingDialogOpen = true">example</a>)
                                </div>
                                <div>
                                    Send a copy of the approval along with the script directly to
                                    {{ siteProvider.name }} and request that they install them on {{ dealerHostname }}
                                    (<a
                                        href="#"
                                        @click.prevent="eventFlowMessagingDialogOpen = true">example</a>)
                                </div>
                                <div>
                                    Once {{ siteProvider.name }} has successfully installed the event flow the events
                                    will start flowing into reporting.
                                </div>
                            </styled-list>
                        </div>
                        <div class="flex xs12 md5 offset-md1 mb-5">
                            <styled-button
                                class="mb-4"
                                outline
                                gray
                                full-width
                                @click="eventFlowMessagingDialogOpen = true">
                                VIEW &amp; SEND EMAILS
                            </styled-button>
                            <div
                                v-if="siteProvider.support_phone || siteProvider.support_email"
                                class="contact-panel">
                                <p>{{ siteProvider.name }} Contact Information</p>
                                <div class="layout wrap">
                                    <data-field
                                        v-if="siteProvider.support_phone"
                                        class="mr-3"
                                        no-indent>
                                        <template #label>
                                            Phone
                                        </template>
                                        <a
                                            :href="`tel:${siteProvider.support_phone}`"
                                            target="_blank">
                                            {{ siteProvider.support_phone }}
                                        </a>
                                    </data-field>
                                    <data-field
                                        v-if="siteProvider.support_email"
                                        no-indent>
                                        <template #label>
                                            Email
                                        </template>
                                        <a
                                            :href="`mailto:${siteProvider.support_email}`"
                                            target="_blank">
                                            {{ siteProvider.support_email }}
                                        </a>
                                    </data-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="layout align-center">
                <back-button
                    @click="$store.dispatch('goToPreviousStep')" />
                <styled-button
                    class="ml-auto"
                    :loading="loadingDeployment"
                    :disabled="!valid"
                    @click="onContinue">
                    CONTINUE
                </styled-button>
            </div>
        </template>
    </div>
</template>

<script>
import {
    downloadEventFlowCode,
    getEventFlowScript
} from '@/helpers/eventFlowCode';
import {
    downloadGtmCode,
    getMainScript,
    getSecondaryScript
} from '@/helpers/gtmCode';
import BackButton from '@/components/globals/BackButton';
import Loader from '@/components/globals/Loader';
import DataField from '@/components/globals/DataField';
import CopyTextArea from '@/components/globals/CopyTextArea';
import StyledSlideout from '@/components/globals/StyledSlideout';
import StyledCallout from '@/components/globals/StyledCallout';
import StatusIcon from '@/components/globals/StatusIcon';
import StyledButton from '@/components/globals/StyledButton';
import StyledList from '@/components/globals/StyledList';
import ResendSiteProviderDialog from '@/components/dealers/settings/panels/ResendSiteProvider';
import ResendEventFlowDialog from '@/components/dealers/settings/panels/ResendEventFlow';
import { mapState, mapGetters } from 'vuex';
import { requiredRules } from '@/helpers/validationRules';
import { UPDATE_ONBOARDING_DATA } from '@/store/mutation-types';
import { GENERIC_SITE_PROVIDER } from '@/helpers/globals';
import uuidv1 from 'uuid/v1';
import stripIndent from 'strip-indent';
import NewTagManagerProviderRequestSlideout from '@/components/globals/NewTagManagerProviderRequestSlideout';

export default {
    name: 'OnboardingTagManager',
    components: {
        BackButton,
        Loader,
        DataField,
        CopyTextArea,
        StyledButton,
        StyledSlideout,
        StyledList,
        StyledCallout,
        StatusIcon,
        ResendSiteProviderDialog,
        ResendEventFlowDialog,
        NewTagManagerProviderRequestSlideout
    },
    data() {
      return {
          loadingInit: false,
          loadingDeployment: false,
          initErrors: [],
          requiredRules,
          siteProviderId: null,
          siteProviders: [],
          loadingSiteProviders: false,
          codeDialogOpen: false,
          messagingDialogOpen: false,
          showNewTagManagerDialog:false,
          eventFlowCodeDialogOpen: false,
          eventFlowMessagingDialogOpen: false,
      };
    },
    computed: {
        ...mapState({
            onboardingData: (state) => state.onboarding.data,
            currentDealer: (state) => state.dealer.currentDealer,
            currentAgency: (state) => state.agency.currentAgency
        }),
        ...mapGetters([
            'dealerHostname',
            'dealerHasGenericSiteProvider',
            'dealerUsingEventFlow',
            'onboardingStatus',
            'dealerIsUsingGtm',
            'dealerShouldCreateAGaProperty',
            'dealerShouldCreateAGtmContainer',
            'dealerInAutoVertical'
        ]),
        valid() {
            if (
                !this.siteProviderId &&
                !this.siteProviderUnknown &&
                // We only need to worry about the site provider selection
                // if the account is automotive
                this.dealerInAutoVertical
            ) {
                return false;
            }
            return true;
        },
        siteProviderRules() {
            if (this.siteProviderUnknown) {
                return [];
            }

            return [v => !!v || 'Click on the link below if you don\'t see your provider'];
        },
        siteProviderUnknownRules() {
            if (this.siteProviderId) {
                return [];
            }

            return [v => !!v || 'Check this field if you don\'t know your provider'];
        },
        siteProvider() {
            return this.siteProviders.find(siteProvider => {
                return siteProvider.id === this.siteProviderId;
            });
        },
        mainScript() {
            return getMainScript(this.currentDealer.tag_manager_container_id, this.currentDealer.agency.name);
        },
        secondaryScript() {
            return getSecondaryScript(this.currentDealer.tag_manager_container_id, this.currentDealer.agency.name);
        },
        eventFlowFeatureComplete() {
            if(this.onboardingStatus.length) {
                const eventFlow = this.onboardingStatus.find(feature => feature.feature === 'event_flow');
                return ('complete' === eventFlow.status);
            } else {
                return true;
            }
        },
        publicGtmSetupUrl() {
            const gtmSetupRoute = this.$router.resolve({
                name: 'gtm-setup',
                params: {
                    container_id: this.currentDealer.tag_manager_container_id
                }
            });

            return window.location.origin + gtmSetupRoute.href;
        },
        publicEventFlowSetupUrl() {
            const eventFlowSetupRoute = this.$router.resolve({
                name: 'event-flow-setup',
                params: {
                    uuid: this.currentDealer.uuid
                }
            });

            return window.location.origin + eventFlowSetupRoute.href;
        },
        eventFlowScript() {
            return getEventFlowScript(this.currentDealer.uuid, this.currentDealer.agency.name);
        },
        gtmDeployed: {
            get() {
                return this.onboardingData.gtmDeployed || false;
            },
            set(isDeployed) {
                // Track that the container has been deployed
                this.$store.commit(UPDATE_ONBOARDING_DATA, {
                    gtmDeployed: isDeployed
                });
            }
        },
        gtmDeploymentChannelId: {
            get() {
                return this.onboardingData.gtmDeploymentChannelId || uuidv1();
            },
            set(channelId) {
                this.$store.commit(UPDATE_ONBOARDING_DATA, {
                    gtmDeploymentChannelId: channelId
                });
            }
        },
        siteProviderUnknown: {
            get() {
                return this.onboardingData.siteProviderUnknown || false;
            },
            set(value) {
                this.$store.commit(UPDATE_ONBOARDING_DATA, {
                    siteProviderUnknown: value
                });
            }
        },
    },
    created() {
        this.getSiteProviders();

        // If the dealer doesn't have a generic site provider select it
        // the user can maintain the generic site provider if they choose
        if (!this.dealerHasGenericSiteProvider) {
            this.siteProviderId = this.currentDealer.site_provider_id;
        }

        this.init();
    },
    methods: {
        async init() {
            this.loadingInit = true;
            this.initErrors = [];

            this.dealerShouldCreateAGtmContainer && await this.setupGtm();
            this.dealerShouldCreateAGaProperty && await this.setupGoogleAnalytics();

            // Now refresh the dealer
            await this.$store.dispatch('updateCurrentDealer');
            this.loadingInit = false;
        },
        async setupGtm() {
            // Only setup if a UA code hasn't been set
            if (this.currentDealer.tag_manager_container_id) {
                return;
            }

            try {
                this.error = null;
                await this.$http.post(`/dealers/${this.currentDealer.id}/run-action/create-gtm-container`);
            } catch (error) {
                console.error('Error creating GTM continer', error);
                if (error.response) {
                    this.initErrors = [
                        ...this.initErrors,
                        ...error.response.data.error.messages
                    ];
                } else {
                    this.initErrors.push('Error creating GTM container');
                }
            }
        },
        async setupGoogleAnalytics() {
            // Only setup if a UA code hasn't been set
            if (this.currentDealer.ua_code) {
                return;
            }

            try {
                this.error = null;
                await this.$http.post(`/dealers/${this.currentDealer.id}/run-action/create-ga-property`);
            } catch (error) {
                console.error('Error creating GA Property');
                if (error.response) {
                    this.initErrors = [
                        ...this.initErrors,
                        ...error.response.data.error.messages
                    ];
                } else {
                    this.initErrors.push('Error creating GA Property', error);
                }
            }
        },
        async getSiteProviders() {
            try {
                this.loadingSiteProviders = true;
                const response = await this.$apiRepository.getSiteProviders();
                this.siteProviders = response.data.data;
            } finally {
                this.loadingSiteProviders = false;
            }
        },
        onDownloadCode() {
            downloadGtmCode(this.currentDealer.tag_manager_container_id);
        },
        onDownloadEventFlowCode() {
            downloadEventFlowCode(this.currentDealer.uuid, this.currentDealer.name);
        },
        async onContinue() {
            try {
                this.errors = [];
                this.loadingDeployment = true;

                // The site provider needs to be configured before we can deploy GTM
                // but only move forward if it's been set or is different from the
                // current site provider
                if (this.siteProviderId
                    && this.siteProviderId !== this.currentDealer.site_provider_id) {
                    await this.setSiteProvider();
                }

                // Create a support ticket to let support know there's a
                if (this.siteProviderUnknown) {
                    this.createNewWebsiteProviderSupportTicket();
                }

                // Deploy event flow
                await this.updateEventFlowFeatureConfig();

                // If the dealer is using event flow just go next step
                if (this.dealerUsingEventFlow) {
                    this.$store.dispatch('goToNextStep');
                    return;
                }

                // new deployment
                await this.deployEventFlow();

                // Continue once the deployment returns so we know the backend process has started
                this.$store.dispatch('goToNextStep');
            } finally {
                this.loadingDeployment = false;
            }
        },
        async updateEventFlowFeatureConfig() {
            const eventFlowDealerFeature = this.onboardingStatus.find(feature => feature.feature === 'event_flow');
            const config = {};
            config.event_flow_installed = true;
            await this.$apiRepository.updateDealerFeatureConfiguration(eventFlowDealerFeature.dealer_feature_id, config);
            this.$store.dispatch('updateOnboardingStatus', {
                features: ['event_flow']
            });
            await this.updateDealerUsesEventFlow();
        },
        async updateDealerUsesEventFlow() {
            const data = {
                uses_event_flow: true
            };
            await this.$http.patch(`/dealers/${this.currentDealer.id}`, data);
        },
        async setSiteProvider() {
            try {
                // Set the site provider or fall back to the generic provider if none is set
                await this.$http.patch(`/dealers/${this.currentDealer.id}`, {
                    site_provider_id: this.siteProviderId || GENERIC_SITE_PROVIDER
                });
                // Refresh the current dealer to register the updates
                await this.$store.dispatch('updateCurrentDealer');
            } catch (error) {
                console.error('Error deploying GTM container', error);
                this.errors.push('GTM container deployment could not be initiated');
            }
        },
        async deployGtm() {
            try {
                await this.$http.post(`/dealers/${this.currentDealer.id}/setup-tag-manager`, {
                    channel_id: this.gtmDeploymentChannelId,
                });

                this.gtmDeployed = true;
            } catch (error) {
                console.error('Error deploying GTM container', error);
                this.errors.push('GTM container deployment could not be initiated');
            }
        },
        createNewWebsiteProviderSupportTicket() {
            const ticketContent = stripIndent(`
                The generic site provider was selected during onboarding for
                ${this.dealerHostname} (${this.currentDealer.tag_manager_container_id}).

                Please review the GTM configuration once deployment is complete!
            `);

            const data = {
                override_email_cc: [],
                subject: 'Generic Site Provider Used in GTM Buildout',
                agencyId: this.currentDealer.agency_id,
                dealerId: this.currentDealer.id,
                ticketTypes: ['onboarding_-_new_site_provider_setup'],
                ticketContent
            };

            this.$http.post(`/dealers/${this.currentDealer.id}/tickets`, data);
        },
        async deployEventFlow() {
            try {
                await this.$http.post(`dealers/${this.currentDealer.id}/gtm-to-eventflow`, {
                    channel_id: this.gtmDeploymentChannelId,
                });

                this.gtmDeployed = true;
            } catch(error) {
                console.error('Error deploying event flow', error);
                this.errors.push('Event flow deployment could not be initiated');
            }
        }
    }
};
</script>


<style lang="scss" scoped>
.contact-panel {
    padding: 30px;
    background-color: rgba($gray, 0.05);
}
</style>
